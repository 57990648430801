<template>
  <div style="height: 100%">
    <div id="navigation">首页</div>
    <el-container style="height: calc(100% - 40px); background: #f4f4f4">
      <el-main style="position: relative; box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2); border-radius: 8px; background: white">
        <div style="height: 206px" v-if="showFold">
          <div class="type-title">文件夹</div>
          <template v-for="(item, index) in foldList">
            <div
              v-if="!item.hidden"
              :key="index"
              :style="folderStyle(index)"
              class="inner-img"
              @click="floderClick(item, index, 'folder')"
              @dblclick="floderDbClick(item, 'folder')"
              @mouseenter="mouseenter(item)"
              @mouseleave="mouseleave(item)"
            >
              <el-image class="img-item" :src="require('@/assets/img/icon_folder_def.png')" fit="contain"> </el-image>
              <div class="img-title">
                <span>{{ item.name }}</span>
              </div>
              <!-- <el-popover trigger="click" :ref="`popover-folder-${index}`" placement="right">
              <div @click="toDown()" class="popover-btn">下载</div>
              <div class="more" v-show="item.isShow" v-bind:title="'更多操作'" slot="reference">
                <i class="el-icon-more"></i>
              </div>
            </el-popover> -->
            </div>
          </template>
        </div>

        <div style="height: 1px; background: #d4d6d9; opacity: 0.5" v-if="showFold"></div>

        <div style="padding-top: 20px">
          <div class="type-title">设备</div>
          <div>
            <div v-if="dataList.length">
              <div
                v-for="(item, index) in dataList"
                :key="index"
                :style="deviceStyle(index)"
                class="inner-img"
                @click="floderClick(item, index, 'device')"
                @dblclick="floderDbClick(item, 'device')"
                @mouseenter="mouseenter(item)"
                @mouseleave="mouseleave(item)"
              >
                <el-image class="img-item" v-if="item.isUnbound" :src="require('@/assets/img/icon_equipment-folder_no-link_def.png')" fit="contain">
                </el-image>
                <el-image class="img-item" v-else :src="require('@/assets/img/icon_equipment-folder_link_def.png')" fit="contain"> </el-image>
                <div class="img-title">
                  <el-tooltip effect="dark" :content="item.name" placement="bottom-start" :visible-arrow="false">
                    <span> {{ item.name }} </span>
                  </el-tooltip>
                </div>
                <el-popover trigger="click" :ref="`popover-deivce-${index}`" placement="right">
                  <div @click="toDown()" class="popover-btn">下载</div>
                  <div v-if="item.isUnbound" @click="toDelete()" class="popover-btn">删除</div>
                  <div class="more" v-show="item.isShow" v-bind:title="'更多操作'" slot="reference">
                    <i class="el-icon-more"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <div v-else style="height: 100%">
              <el-empty style="height: 100%"></el-empty>
            </div>
          </div>
        </div>
      </el-main>
      <div style="height: 100%; width: 20px; background: #f4f4f4"></div>
      <el-aside width="323px" class="info-aside">
        <div style="font-size: 16px; font-family: PingFangSC-Medium, PingFang SC; font-weight: bold; color: #3a3b3e; margin: 24px">详细信息</div>
        <el-descriptions
          :column="1"
          :colon="false"
          labelClassName="detail-label"
          v-if="showFlag"
          v-loading="asideLoading"
          style="word-break: break-all"
        >
          <el-descriptions-item label="产品系列" v-if="currentType === 'device'">{{ deviceInfo.suit }}</el-descriptions-item>
          <el-descriptions-item label="设备型号" v-if="currentType === 'device'">{{ deviceInfo.model }}</el-descriptions-item>
          <el-descriptions-item label="名称">{{ deviceInfo.name }}</el-descriptions-item>
          <el-descriptions-item label="创建时间" v-if="currentType === 'device'">{{ deviceInfo.createTime }}</el-descriptions-item>
          <el-descriptions-item label="文件个数">{{ deviceInfo.fileNum }}</el-descriptions-item>
          <el-descriptions-item label="大小">{{ deviceInfo.fileSize }}</el-descriptions-item>
        </el-descriptions>
        <div v-else style="height: calc(100% - 256px); padding: 0 24px">
          <img :src="require('@/assets/img/sample_detailed-info.png')" width="96%" alt="" />
          <div style="font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #4d4e52; text-align: center; margin-top: 20px">
            选择以展示详细信息
          </div>
        </div>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import { changeToThousand } from '@/utils'
// import axios from 'axios'
// import Vue from 'vue'
export default {
  name: 'MyImg',
  data() {
    return {
      deviceInfo: {},
      dataList: [],
      currentFolderIndex: undefined,
      currentFolderObj: {},
      asideLoading: false,
      foldList: [
        // { name: '我的资源', type: 0 },
        { name: '智能拍摄集', type: 1, key: 2, hidden: true },
        { name: '诊断规则集', type: 2, key: 4, hidden: true }
      ],
      currentDeviceIndex: undefined,
      currentDeviceObj: {},
      showFlag: false,
      currentType: '',
      showFold: false
    }
  },
  computed: {
    btnDisabled() {
      return this.currentFolderIndex !== 0 && !this.currentFolderIndex
    },
    folderStyle() {
      return (index) => {
        return this.currentFolderIndex === index ? 'box-shadow: 0px 4px 10px 0px rgba(168, 171, 179, 0.29);border-radius: 8px;' : ''
      }
    },
    deviceStyle() {
      return (index) => {
        return this.currentDeviceIndex === index ? 'box-shadow: 0px 4px 10px 0px rgba(168, 171, 179, 0.29);border-radius: 8px;' : ''
      }
    },
    authList() {
      return this.$store.state.authList
    }
  },
  watch: {
    authList(val) {
      this.controlFolder(val)
    }
  },
  created() {
    this.controlFolder(this.authList)
    this.getDataList()
  },
  methods: {
    // 控制文件夹
    controlFolder(val = []) {
      let count = 0
      this.foldList.forEach((item) => {
        for (let i = 0; i < val.length; i++) {
          if (item.key === val[i]) {
            item.hidden = false
            count++
            break
          }
        }
      })
      if (count) {
        this.showFold = true
      } else {
        this.showFold = false
      }
    },
    mouseenter(item) {
      this.$set(item, 'isShow', true)
    },
    mouseleave(item) {
      this.$set(item, 'isShow', false)
    },
    // 下载
    toDown() {
      if (this.deviceInfo.fileNum === 0) {
        this.$message.error('该设备下没有图片')
        return
      }
      window.open(
        this.currentType === 'device'
          ? this.$http.adornUrl('/api/v1/files/download') + '?deviceId=' + this.currentDeviceObj.deviceId + '&fileName=' + this.currentDeviceObj.name
          : this.$http.adornUrl('/api/v1/files/special/download') + '?type=' + this.currentFolderObj.type,
        '_blank'
      )
      // window.location.href =
      //   this.currentType === 'device'
      //     ? this.$http.adornUrl('/api/v1/files/download') + '?deviceId=' + this.currentDeviceObj.deviceId + '&fileName=' + this.currentDeviceObj.name
      //     : this.$http.adornUrl('/api/v1/files/special/download') + '?type=' + this.currentFolderObj.type

      // axios({
      //   timeout: 1000 * 60 * 5,
      //   url: this.$http.adornUrl(`${this.currentType === 'device' ? '/api/v1/files/download' : '/api/v1/files/special/download'}`),
      //   method: 'post',
      //   params: {
      //     deviceId: this.currentDeviceObj.deviceId,
      //     type: this.currentFolderObj.type
      //   },
      //   responseType: 'blob',
      //   headers: {
      //     Authorization: Vue.cookie.get('token'),
      //     'x-app-name': 'web'
      //   }
      // }).then(res => {
      //   const blob = res.data
      //   const fileName = (this.currentType === 'device' ? this.currentDeviceObj.name : this.currentFolderObj.name) + '.zip'

      //   if ('download' in document.createElement('a')) {
      //     // 非IE下载
      //     const elink = document.createElement('a')
      //     elink.download = fileName
      //     elink.style.display = 'none'
      //     elink.href = URL.createObjectURL(blob)
      //     document.body.appendChild(elink)
      //     elink.click()
      //     URL.revokeObjectURL(elink.href) // 释放URL 对象
      //     document.body.removeChild(elink)
      //   } else {
      //     // IE10+下载
      //     navigator.msSaveBlob(blob, fileName)
      //   }
      // })
    },
    // 删除
    toDelete() {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">确定进行删除操作？
        </div> </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(`/api/v1/folder/${this.currentDeviceObj.id}`),
            method: 'delete',
            params: this.$http.adornParams()
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    floderDbClick(item, type) {
      // 当前文件夹
      sessionStorage.currFolder = JSON.stringify(item)
      if (type === 'folder') {
        this.$router.push('/shootAndRule')
      } else {
        this.$router.push('/myFile')
      }
    },
    // 单击
    floderClick(item, index, type) {
      if (type === 'folder') {
        this.currentFolderIndex = index
        this.currentFolderObj = item
        this.currentDeviceIndex = undefined
        this.currentType = 'folder'
      } else {
        this.currentDeviceIndex = index
        this.currentDeviceObj = item
        this.currentFolderIndex = undefined
        this.currentType = 'device'
      }
      this.showFlag = true

      // 设备点击
      this.asideLoading = true
      this.$http({
        url: this.$http.adornUrl('/api/v1/files/countFiles'),
        method: 'get',
        params: this.$http.adornParams({
          deviceId: item.deviceId,
          type: item.type
        })
      })
        .then((res) => {
          this.deviceInfo = item
          if (res.code === 0) {
            const data = res.data || {}
            this.$set(this.deviceInfo, 'fileNum', data.fileCount)
            // 文件大小按千位分隔符格式显示
            this.$set(this.deviceInfo, 'fileSize', changeToThousand(data.fileSize) + 'KB')
          } else {
            this.$message.error(res.msg)
          }
          this.asideLoading = false
        })
        .catch(() => {
          this.deviceInfo = item
          this.asideLoading = false
        })
    },
    getDataList() {
      // 设备
      this.$http({
        url: this.$http.adornUrl('/api/v1/folder/index'),
        method: 'get',
        params: this.$http.adornParams({
          pageNum: 1,
          pageSize: 99999
        })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data
          this.dataList = data.list
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// element
.info-aside {
  overflow: auto;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(168, 171, 179, 0.2);
  border-radius: 8px;
  background: white;
  ::v-deep .el-input__inner {
    height: 40px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
.el-divider__text {
  font-weight: bold;
}
.el-divider--horizontal {
  background: #02a7f0;
}
::v-deep .el-form-item__content {
  margin-right: 20px;
  box-sizing: border-box;
}
::v-deep .el-input-number {
  width: 100%;
}

// new
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
}
.type-title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #3a3b3e;
  margin-bottom: 16px;
  height: 32px;
  line-height: 32px;
}
.inner-img {
  width: 112px;
  height: 132px;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 10px 0 14px;
  position: relative;
  cursor: pointer;
  text-align: center;
  .img-item {
    margin: 16px 29px 15px 29px;
  }
  .img-title {
    text-align: center;
    margin: 0 0 8px 0;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .more {
    position: absolute;
    top: -2px;
    right: 4px;
  }
  .more:hover {
    color: #a8a9ee;
  }
  .more:active {
    color: #5053dd;
  }
}
.popover-btn {
  cursor: pointer;
}
.popover-btn:hover {
  color: #a8a9ee;
}
.popover-btn:active {
  color: #5053dd;
}
.popover-btn + .popover-btn {
  margin-top: 10px;
}
</style>
